<template>
    <v-container class="pa-16">
      <v-data-table
        :headers="headers"
        :items="dossiers"
        class="elevation-0 myTable"
        disable-pagination
        hide-default-footer
        :options.sync="options"
        :server-items-length="total"
        :header-props="{ sortIcon: null }"
      >
        <template v-slot:no-data>
          Aucun dossier
        </template>
        <template v-slot:top>
          <v-toolbar flat extended extension-height="100">
            <v-toolbar-title id="toolbar-title">Dossiers </v-toolbar-title>
            <text-input
              prepend-inner-icon="mdi-magnify"
              @keyup="handleSearch"
              class="ml-5 mt-5"
              id="search"
              v-model="filteredItem.name"
              placeholder="Nom du dossier"
            />
            <v-spacer></v-spacer>
          </v-toolbar>
        </template>
        <template v-slot:item.name="{ item }">
          <v-icon v-if="item.status === 0" class="outlined inactive-status"
            >mdi-close</v-icon
          >
          <v-icon v-if="item.status === 1" class="outlined active-status"
            >mdi-check</v-icon
          >
          {{ item.name }}
        </template>
        <template v-slot:item.affectation="{ item }">
          <div :key="item.agent">
            <template v-for="i in item.affectation">
              <Avatar
                user="false"
                :item="i.agent"
                :key="i.agent?.id"
                style="margin-left:-10px"
              />
            </template>
          </div>
        </template>
      </v-data-table>
    </v-container>
  </template>
  <script>
  import {
    TextInput,
    Avatar,
  } from "@/components/base";
  import { infinityScrollMixin } from "@/utils/infinityScrollMixin";
import { getListFolderByAgentId } from "../../../services/dossiers.api";
  export default {
    components: {TextInput,Avatar},
    mixins: [infinityScrollMixin],
    data: () => ({
      total: 0,
      options: {},
      text: "",
      items: [],
      headers: [
        {
          text: "Dossier",
          align: "start",
          sortable: true,
          value: "name",
        },
        { text: "Client", value: "client.name" },
        { text: "Equipe", value: "affectation" },
        { text: "Heures estimées", value: "totEstimatedHours" },
        { text: "Heures consommées", value: "totConsumedHours" }
      ],
      dossiers: [],
      filteredItem: {},
      timeout: null,
      pageNumber: 1,
      last_page: 0,
    }),
    watch: {
      options: {
        handler() {
          this.dossiers = [];
          this.pageNumber = 1;
          getListFolderByAgentId(this.pageNumber,this.filteredItem).then((response) => {
            this.dossiers = response.data.data;
            for (let i in response.data.data) {
              let ids = this.dossiers[i].affectation.map((o) => o.user_id);
              this.dossiers[i].affectation = this.dossiers[i].affectation.filter(
                ({ user_id }, index) => !ids.includes(user_id, index + 1)
              );
            }
            this.last_page = response.data.last_page;
            this.total = response.data.total;
          });
        },
        deep: true,
      },
      endOfTheScreen(endOfTheScreen) {
        if (endOfTheScreen === true && this.pageNumber < this.last_page) {
          this.pageNumber += 1;
          getListFolderByAgentId(this.pageNumber,this.filteredItem).then((response) => {
            let responseArray = response.data.data;
            for (let i in response.data.data) {
              let ids = this.dossiers[i].affectation.map((o) => o.user_id);
              this.dossiers[i].affectation = this.dossiers[i].affectation.filter(
                ({ user_id }, index) => !ids.includes(user_id, index + 1)
              );
            }
            this.dossiers = [...this.dossiers, ...responseArray];
            this.total = response.data.total;
          });
        }
      },
    },
    created() {
      this.initialize();
    },
    methods: {
      getProjects() {
        getListFolderByAgentId(this.pageNumber,this.filteredItem).then((response) => {
          this.dossiers = response.data.data;
          for (let i in response.data.data) {
            let ids = this.dossiers[i].affectation.map((o) => o.user_id);
            this.dossiers[i].affectation = this.dossiers[i].affectation.filter(
              ({ user_id }, index) => !ids.includes(user_id, index + 1)
            );
          }
          this.last_page = response.data.last_page;
          this.total = response.data.total;
        });
      },
      handleSearch() {
        if (this.filteredItem.name !== "") {
          clearTimeout(this.timeout);
          this.timeout = setTimeout(() => {
            this.getProjects();
          }, 1000);
        } else {
          this.getProjects();
        }
      },
      initialize() {
        this.getProjects();
      },
    },
  };
  </script>
  <style scoped>
  .myTable .datatable thead th.column.sortable i {
    display: none;
  }
  
  .v-icon.outlined {
    border: 1px solid currentColor;
    border-radius: 50%;
    padding: 3px;
  }
  </style>
  